import { ThemeProvider } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { router } from './router';
import { theme } from './theme';

export { theme };

export const MEDIA_QUERY = 'only screen and (orientation: portrait) and (hover: none) and (pointer: coarse)'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <ParallaxProvider>
        <RouterProvider router={router} />
      </ParallaxProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
